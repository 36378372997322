'use client';
import '@/styles/globals.css';
import { UserAuthContext } from '@/context/UserContext';
import { ShoppingCartContext } from '@/context/shoppingCartContext';
import Script from 'next/script';
import { LimeFormsContext } from '@/context/LimeFormContext';
import { NavbarModalsContext } from '@/context/NavbarModalsContext';

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="sv">
      <head>
        <meta
          name="google-site-verification"
          content="lXgkBGjNSN4s-zSoxV8yTvesuio8hoBuMSKMXEytGVo"
        />
        <meta
          name="facebook-domain-verification"
          content="a064p44nehctf23q87aio60w2tewzw"
        />
        <link rel="icon" href="/favicon.png" />
      </head>
      <body>
        <Script
          src="/vendor/lite-yt-embed.js"
          stylesheets={['/vendor/lite-yt-embed.css']}
        />
        <Script
          src="https://bot.leadoo.com/bot/dynamic.js?company=lUcpzQuF&mode=spa"
          async
        />
        <Script id="googleTagManager">
          {`
            (function (w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
              });
              const f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${googleTagManagerId}');
          `}
        </Script>
        {!!googleTagManagerId && (
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>
          </noscript>
        )}
        <LimeFormsContext>
          <UserAuthContext>
            <ShoppingCartContext>
              <NavbarModalsContext>{children}</NavbarModalsContext>
            </ShoppingCartContext>
          </UserAuthContext>
        </LimeFormsContext>
      </body>
    </html>
  );
}
